import(/* webpackChunkName: "fanalytics" */ '@fiizy/fanalytics');
import '../css/kasowo.scss';
import hideLoading from "../../../common/scripts/hideLoading";
import cookieLaw, {agreeCookies} from "../../../common/scripts/cookieLaw";
import loadMenu from "../../../common/scripts/loadMenu";
import isMobile from "../../../common/scripts/isMobile";
import tableOfContentsOnMobile from "../../../common/scripts/tableOfContents";
import {lazyLoadImages} from "../../../common/scripts/lazyLoad";
import {applyPolyfills, defineCustomElements} from '@fiizy/stylebook/loader';

applyPolyfills().then(() => {
    defineCustomElements();
});

const product = document.querySelector(".product");

function navigateForm() {
    if (!product || !product.dataset) return
    let applicationPrincipal = product.dataset.applicationPrincipal;

    product.querySelector('fi-button').addEventListener('click', function () {
        if (this.loading) {
            return;
        }

        this.loading = true;
        const urlSearchParams = new URLSearchParams(window.location.search);
        if(product.dataset.product) urlSearchParams.set('loanType', product.dataset.product);
        if (applicationPrincipal) urlSearchParams.set('applicationPrincipal', applicationPrincipal);
        if (product.dataset.xps) urlSearchParams.set('xps', product.dataset.xps);
        agreeCookies();
        window.location.href = 'process?' + urlSearchParams.toString();
    })

    const rangeSliderElement = product.querySelector('fi-slider');
    if (rangeSliderElement) {
        rangeSliderElement.addEventListener('fiInput', function (event) {
            applicationPrincipal = event.detail.toString();
        })
    }
}

function collapsibleDisclaimer() {
    const collapsableDisclaimer = document.querySelector('.footer-disclaimer.collapsable');

    if(collapsableDisclaimer) {
        const toggleButton = document.getElementById("collapseDisclaimer");
        toggleButton.addEventListener('click', function () {
            collapsableDisclaimer.classList.toggle('full');
            toggleButton.style.display = 'none';
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    loadMenu();

    if (isMobile()) {
        tableOfContentsOnMobile();
    }
    navigateForm()
    cookieLaw();
    hideLoading();
    lazyLoadImages();
    collapsibleDisclaimer();
});
